// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-post-js": () => import("./../../../src/components/post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-go-js": () => import("./../../../src/pages/go.js" /* webpackChunkName: "component---src-pages-go-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-a-beginners-guide-to-knowing-nothing-about-pc-building-mdx": () => import("./../../../src/pages/posts/a-beginners-guide-to-knowing-nothing-about-pc-building.mdx" /* webpackChunkName: "component---src-pages-posts-a-beginners-guide-to-knowing-nothing-about-pc-building-mdx" */),
  "component---src-pages-posts-apples-new-m-1-chip-blurring-the-future-for-pcs-mdx": () => import("./../../../src/pages/posts/apples-new-m1-chip-blurring-the-future-for-pcs.mdx" /* webpackChunkName: "component---src-pages-posts-apples-new-m-1-chip-blurring-the-future-for-pcs-mdx" */),
  "component---src-pages-posts-bleeding-edge-pc-parts-november-2020-mdx": () => import("./../../../src/pages/posts/bleeding-edge-pc-parts-november-2020.mdx" /* webpackChunkName: "component---src-pages-posts-bleeding-edge-pc-parts-november-2020-mdx" */),
  "component---src-pages-posts-parts-of-a-pc-mdx": () => import("./../../../src/pages/posts/parts-of-a-pc.mdx" /* webpackChunkName: "component---src-pages-posts-parts-of-a-pc-mdx" */),
  "component---src-pages-posts-prepare-for-launch-mdx": () => import("./../../../src/pages/posts/prepare-for-launch.mdx" /* webpackChunkName: "component---src-pages-posts-prepare-for-launch-mdx" */),
  "component---src-pages-posts-stop-the-bots-so-i-can-buy-my-hardware-its-not-that-simple-mdx": () => import("./../../../src/pages/posts/stop-the-bots-so-i-can-buy-my-hardware-its-not-that-simple.mdx" /* webpackChunkName: "component---src-pages-posts-stop-the-bots-so-i-can-buy-my-hardware-its-not-that-simple-mdx" */),
  "component---src-pages-posts-testing-the-27-lg-ultragear-4-k-1-ms-144-hz-g-sync-gaming-monitor-mdx": () => import("./../../../src/pages/posts/testing-the-27-lg-ultragear-4k-1ms-144hz-g-sync-gaming-monitor.mdx" /* webpackChunkName: "component---src-pages-posts-testing-the-27-lg-ultragear-4-k-1-ms-144-hz-g-sync-gaming-monitor-mdx" */),
  "component---src-pages-posts-welcome-to-refresh-mdx": () => import("./../../../src/pages/posts/welcome-to-refresh.mdx" /* webpackChunkName: "component---src-pages-posts-welcome-to-refresh-mdx" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-templates-list-js": () => import("./../../../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-stop-js": () => import("./../../../src/templates/stop.js" /* webpackChunkName: "component---src-templates-stop-js" */)
}

